import React from 'react';
import HeaderMenu from "../../components/Header/HeaderMenu";
import Advantages from "../../components/Advantages/Advantages";

const AdvantagesPages = () => {
    return (
        <>
            <div className={'main-container'}>
                <div className={'header relative'}>
                    <HeaderMenu/>
                </div>
            </div>
            <div className={'main-container'}>
                <Advantages/>
            </div>
        </>
    );
};

export default AdvantagesPages;
