export const ADVANTAGES_DATA = {
    'nadezhnyy-emitent': {
        title: 'Надежный эмитент',
        content: `
        <p>Эмитентом долговых обязательств является компанией TradeChangePlace OÜ, зарегистрированной по адресу Harju maakond, Таллинн, Kesklinna linnaosa, Tuukri tn 19-315, 10120, с офисным номером 14789801.</p>

        <h2 class="benefit-subtitle">Высокий кредитный рейтинг</h2>
        <p>Компания TradeChangePlace OÜ имеет публичный кредитный рейтинг AA+, что выше рейтинга известных банков, таких как Тинькофф, который имеет кредитный рейтинг AA-. Это свидетельствует о еще более высоком уровне надежности и финансовой устойчивости TradeChangePlace OÜ.</p>

        <h2 class="benefit-subtitle">Официальный логотип и название</h2>
        <p>Все долговые обязательства имеют официальный логотип и название TCPcredit (TCPcr). Эти данные честно отцифрованы и помещены в смарт-контракт с адресом в сети TRON: TCCV3DGw4Xa78bz1F38xDRtcpfERJpMZwM. Каждый 1 TCPcredit равен 1 доллару США.</p>

        <h2 class="benefit-subtitle">Полная обеспеченность</h2>
        <p>Долговые обязательства на 100% обеспечены в специальном фонде банка, что гарантирует их безопасность и возможность погашения в любой момент. При погашении долговых обязательств онлайн компания TradeChangePlace OÜ может конвертировать валюту честно в любую валюту, что обеспечивает удобство и гибкость для клиентов.</p>

        <h2 class="benefit-subtitle">Обеспечительный фонд доходности</h2>
        <p>Фонд доходности по долговым обязательствам позволяет студентам получать стабильный доход. В процессе приобретения долговых обязательств клиентам выдается сертификат, подтверждающий их права и обеспеченность.</p>

        <h2 class="benefit-subtitle">Прозрачность и надежность</h2>
        <p>Компания TradeChangePlace OÜ обеспечивает полную прозрачность всех операций и предоставляет всю необходимую информацию для своих клиентов, что делает взаимодействие надежным и безопасным.</p>

        <p>Присоединяйтесь к нам и начните инвестировать сегодня, чтобы получить стабильный и надежный доход. Свяжитесь с нами для получения более подробной информации!</p>
        `
    },
    'vysokaya-dokhodnost': {
        title: 'Высокая доходность',
        content: `
        <p>Инвестируя в долговые обязательства TradeChangePlace OÜ, вы получаете уникальную возможность обеспечить высокий доход без рисков. Компания предлагает привлекательные условия для инвесторов, обеспечивая стабильную доходность как в рублях, так и в иностранной валюте.</p>

        <h2 class="benefit-subtitle">Доходность в рублях</h2>
        <p>Долговые обязательства компании TradeChangePlace OÜ предлагают доходность от 20% годовых в рублях, что значительно выше среднерыночных показателей и доходности, предлагаемой большинством банков. Это позволяет не только защитить свои сбережения от инфляции, но и значительно приумножить их.</p>

        <h2 class="benefit-subtitle">Доходность в евро</h2>
        <p>Для инвесторов, предпочитающих вклады в иностранной валюте, TradeChangePlace OÜ предлагает доходность от 16% годовых в евро. Это делает долговые обязательства компании привлекательным инструментом для диверсификации инвестиционного портфеля и снижения валютных рисков.</p>

        <h2 class="benefit-subtitle">Гарантии и прозрачность</h2>
        <p>Компания TradeChangePlace OÜ гарантирует полную прозрачность всех операций и предоставляет регулярные отчеты о состоянии ваших инвестиций. Долговые обязательства на 100% обеспечены в специальном фонде банка, что обеспечивает их надежность и безопасность.</p>

        <h2 class="benefit-subtitle">Гибкость и удобство</h2>
        <p>Погашение долговых обязательств возможно в любой момент онлайн, с зачислением средств на ваш банковский счет. Это позволяет максимально гибко управлять своими инвестициями и получать доступ к средствам тогда, когда это необходимо.</p>

        <h2 class="benefit-subtitle">Подтверждение доходности</h2>
        <p>Каждый инвестор получает официальный сертификат, подтверждающий право на получение дохода и обеспеченность долговых обязательств. Этот документ является гарантией вашей инвестиции и свидетельствует о высоком уровне надежности TradeChangePlace OÜ.</p>

        <h2 class="benefit-subtitle">Законодательное соответствие</h2>
        <p>Долговые обязательства TradeChangePlace OÜ регулируются следующими законодательными актами РФ:</p>
        <ul>
            <li><strong>Гражданский кодекс Российской Федерации:</strong></li>
            <ul>
                <li>Глава 42 "Заем и кредит" регулирует отношения, связанные с займами и кредитами. Каждое долговое обязательство оформляется именным сертификатом, что подтверждает права и обязательства сторон, как предусмотрено законодательством.</li>
                <li>Глава 45 "Публичное обещание награды" и Глава 46 "Публичный конкурс" регулируют рекламные и маркетинговые аспекты, что гарантирует прозрачность и законность всех публичных предложений.</li>
            </ul>
            <li><strong>Федеральный закон "О рынке ценных бумаг":</strong></li>
            <ul>
                <li>Долговые обязательства TradeChangePlace OÜ не являются ценными бумагами, и их выпуск и обращение не требуют разрешения регулятора. Это позволяет избежать излишней бюрократии и обеспечивает гибкость для инвесторов.</li>
            </ul>
            <li><strong>Федеральный закон "О защите прав потребителей":</strong></li>
            <ul>
                <li>Компания TradeChangePlace OÜ обеспечивает защиту прав клиентов, инвестирующих в долговые обязательства, предоставляя полную и достоверную информацию о продукте. Каждый инвестор получает именной сертификат, подтверждающий их права и обеспеченность.</li>
            </ul>
            <li><strong>Федеральный закон "О противодействии легализации доходов, полученных преступным путем, и финансированию терроризма" (№ 115-ФЗ):</strong></li>
            <ul>
                <li>Все операции по покупке долговых обязательств проходят через строгие процедуры идентификации клиентов и контроля за подозрительными транзакциями, что исключает возможность использования средств в незаконных целях.</li>
            </ul>
        </ul>

        <h2 class="benefit-subtitle">Присоединяйтесь к нам</h2>
        <p>Не упустите возможность получить высокий доход без рисков. Инвестируйте в долговые обязательства TradeChangePlace OÜ и обеспечьте стабильное будущее своим финансам. Свяжитесь с компанией для получения более подробной информации и начала инвестирования уже сегодня!</p>
        `
    },
    'vysokaya-likvidnost': {
        title: 'Высокая ликвидность',
        content: `
        <p>Инвестируя в долговые обязательства <strong>TradeChangePlace OÜ</strong>, вы получаете возможность гибко управлять своими инвестициями благодаря высокой ликвидности наших продуктов.</p>

        <h2 class="benefit-subtitle">Мгновенное погашение</h2>
        <p>Долговые обязательства <strong>TradeChangePlace OÜ</strong> можно погасить в любой момент онлайн, обеспечивая быстрый доступ к вашим средствам без задержек.</p>

        <h2 class="benefit-subtitle">Обеспеченность ликвидности</h2>
        <p>Наши долговые обязательства на 100% обеспечены в специальном фонде банка и имуществе компании, что гарантирует их надежность и безопасность.</p>

        <h2 class="benefit-subtitle">Конвертация в любую валюту</h2>
        <p>Долговые обязательства <strong>TradeChangePlace OÜ</strong> позволяют вам конвертировать средства в любую валюту, обеспечивая дополнительную гибкость и удобство.</p>

        <h2 class="benefit-subtitle">Присоединяйтесь к нам</h2>
        <p>Воспользуйтесь высокой ликвидностью долговых обязательств <strong>TradeChangePlace OÜ</strong> и обеспечьте стабильное управление своими финансами. Свяжитесь с компанией для получения более подробной информации и начала инвестирования уже сегодня!</p>

        <h2 class="benefit-subtitle">Законодательное соответствие</h2>
        <p>Долговые обязательства <strong>TradeChangePlace OÜ</strong> регулируются и соответствуют следующим законодательным актам РФ:</p>
        <ul>
            <li><strong>Гражданский кодекс Российской Федерации:</strong>
                <ul>
                    <li>Глава 42 "Заем и кредит" регулирует отношения, связанные с займами и кредитами. Каждое долговое обязательство оформляется именным сертификатом, что подтверждает права и обязательства сторон, как предусмотрено законодательством.</li>
                    <li>Глава 45 "Публичное обещание награды" и Глава 46 "Публичный конкурс" регулируют рекламные и маркетинговые аспекты, что гарантирует прозрачность и законность всех публичных предложений.</li>
                </ul>
            </li>
            <li><strong>Федеральный закон "О рынке ценных бумаг":</strong>
                <ul>
                    <li>Долговые обязательства <strong>TradeChangePlace OÜ</strong> не являются ценными бумагами, и их выпуск и обращение не требуют разрешения регулятора. Это позволяет избежать излишней бюрократии и обеспечивает гибкость для инвесторов.</li>
                </ul>
            </li>
            <li><strong>Федеральный закон "О защите прав потребителей":</strong>
                <ul>
                    <li>Компания <strong>TradeChangePlace OÜ</strong> обеспечивает защиту прав клиентов, инвестирующих в долговые обязательства, предоставляя полную и достоверную информацию о продукте. Каждый инвестор получает именной сертификат, подтверждающий их права и обеспеченность.</li>
                </ul>
            </li>
            <li><strong>Федеральный закон "О противодействии легализации доходов, полученных преступным путем, и финансированию терроризма" (№ 115-ФЗ):</strong>
                <ul>
                    <li>Все операции по покупке долговых обязательств проходят через строгие процедуры идентификации клиентов и контроля за подозрительными транзакциями, что исключает возможность использования средств в незаконных целях.</li>
                </ul>
            </li>
        </ul>

        <p>Долговые обязательства <strong>TradeChangePlace OÜ</strong> полностью соответствуют указанным законодательным актам, что гарантирует их законность и надежность. Инвестируйте с уверенностью, зная, что ваши права защищены, а операции прозрачны и законны.</p>
        `
    },
}
