const AdvOne = () => {
    return (
        <svg width="85" height="72" viewBox="0 0 85 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.2" fillRule="evenodd" clipRule="evenodd"
                  d="M24.884 25.4627C25.577 25.6484 25.9882 26.3606 25.8025 27.0536C23.3666 36.1446 28.7615 45.489 37.8526 47.9249C43.8614 49.535 49.9832 47.7242 54.1466 43.6828H52.2164C51.4991 43.6828 50.9174 43.1013 50.9174 42.3839C50.9174 41.6665 51.4991 41.0849 52.2164 41.0849H57.0047C57.7221 41.0849 58.3037 41.6665 58.3037 42.3839V47.7615C58.3037 48.4788 57.7221 49.0604 57.0047 49.0604C56.2872 49.0604 55.7057 48.4788 55.7057 47.7615V45.7855C50.917 50.2719 43.9881 52.2586 37.1802 50.4344C26.7033 47.6271 20.4858 36.8581 23.2931 26.3811C23.4787 25.6882 24.1911 25.277 24.884 25.4627Z"
                  fill="#16ECFB"/>
            <path opacity="0.2" fillRule="evenodd" clipRule="evenodd"
                  d="M59.7554 36.4991C59.0625 36.3134 58.6513 35.6011 58.837 34.9082C61.2729 25.8171 55.8779 16.4727 46.7869 14.0367C40.7781 12.4267 34.6563 14.2375 30.4928 18.2788H32.423C33.1404 18.2788 33.722 18.8604 33.722 19.5778C33.722 20.2952 33.1404 20.8768 32.423 20.8768H27.6348C26.9174 20.8768 26.3358 20.2952 26.3358 19.5778V14.2003C26.3358 13.4829 26.9174 12.9013 27.6348 12.9013C28.3521 12.9013 28.9337 13.4829 28.9337 14.2003V16.1763C33.7225 11.6898 40.6514 9.70318 47.4592 11.5274C57.9362 14.3346 64.1537 25.1035 61.3463 35.5805C61.1607 36.2735 60.4484 36.6848 59.7554 36.4991Z"
                  fill="#16ECFB"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M71.3746 3.46351H13.2946C7.55522 3.46351 2.90252 8.1162 2.90252 13.8556V49.0608C2.90252 54.8002 7.55522 59.4528 13.2946 59.4528H71.3746C77.114 59.4528 81.7666 54.8002 81.7666 49.0608V13.8556C81.7666 8.1162 77.114 3.46351 71.3746 3.46351ZM13.2946 0.865479C6.12037 0.865479 0.304504 6.68135 0.304504 13.8556V49.0608C0.304504 56.235 6.12037 62.0509 13.2946 62.0509H71.3746C78.5489 62.0509 84.3647 56.2351 84.3647 49.0608V13.8556C84.3647 6.68135 78.5489 0.865479 71.3746 0.865479H13.2946Z"
                  fill="#16ECFB"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M28.7792 69.8443C28.7792 69.1269 29.3608 68.5454 30.0782 68.5454H54.9718C55.6892 68.5454 56.2708 69.1269 56.2708 69.8443C56.2708 70.5618 55.6892 71.1433 54.9718 71.1433H30.0782C29.3608 71.1433 28.7792 70.5618 28.7792 69.8443Z"
                  fill="#16ECFB"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M43.7335 21.5382V23.1077C43.9351 23.1418 44.1349 23.1841 44.3328 23.2335C44.9244 23.3814 45.4176 23.5622 45.812 23.7758C46.1899 23.9894 46.5351 24.2031 46.8309 24.4332C46.9102 24.4948 46.9847 24.5518 47.0539 24.6046C47.2429 24.7491 47.392 24.8629 47.4883 24.9591L47.7349 25.2057L46.0421 26.8985C45.9435 26.767 45.812 26.6026 45.6477 26.4218C45.4833 26.2411 45.0889 25.9945 44.4808 25.6822C43.8563 25.37 43.1988 25.2221 42.5086 25.2221C41.6046 25.2221 40.8815 25.4521 40.3228 25.9288C39.764 26.3889 39.4846 26.9477 39.4846 27.5722C39.4846 28.2132 39.7805 28.7391 40.3885 29.1335C40.9801 29.5279 41.7197 29.8402 42.5743 30.0703C43.429 30.3004 44.2836 30.5798 45.1381 30.8756C45.9927 31.1715 46.7158 31.6645 47.3239 32.3547C47.9156 33.0451 48.2279 33.916 48.2279 34.9843C48.2279 36.3648 47.6855 37.5317 46.6337 38.4521C45.8577 39.1206 44.891 39.5549 43.7335 39.7425V41.3784H41.1355V39.7626C40.8049 39.7137 40.4847 39.6438 40.1749 39.5531C39.501 39.3559 38.9423 39.1094 38.515 38.8465C38.0712 38.5671 37.6932 38.2876 37.3645 38.0083C37.0358 37.7289 36.8057 37.4988 36.6579 37.3016L36.4442 36.9894L38.137 35.313C38.1698 35.3787 38.2356 35.4609 38.3178 35.5596C38.3835 35.6582 38.5642 35.8554 38.8272 36.1183C39.0901 36.3813 39.386 36.6277 39.6983 36.8414C39.9941 37.0551 40.3885 37.2523 40.8815 37.4167C41.3582 37.581 41.8512 37.6631 42.3442 37.6631C43.4782 37.6631 44.3493 37.4002 44.9573 36.9072C45.5654 36.3976 45.8777 35.7568 45.8777 34.9843C45.8777 34.3104 45.5654 33.7682 44.9573 33.3409C44.3493 32.9135 43.6262 32.5848 42.7715 32.3383C41.9169 32.0918 41.0787 31.8124 40.2242 31.5166C39.3696 31.2207 38.63 30.7442 38.022 30.0868C37.4138 29.4293 37.118 28.5912 37.118 27.5558C37.118 26.3397 37.6274 25.2878 38.6465 24.3675C39.3474 23.7456 40.1808 23.3338 41.1355 23.1373V21.5382H43.7335Z"
                  fill="#16ECFB"/>
        </svg>

    )
}

export default AdvOne
