import React from 'react';
import "./Blog.scss";
import {Link} from "react-router-dom";
import {BLOG_DATA} from "../../utils/blog";

const Blog = () => {
    return (
        <div className={'blog-items-wrap flex align-start gap-24 mobile-column'}>
            {
                Object.keys(BLOG_DATA).map(k => {
                    return (
                        <div key={k} className={'blog-item flex flex-column gap-16 align-start mobile-w-full'}>
                            <Link to={'/blog/' + k} className={'blog-item-image'}>
                                <img src={BLOG_DATA[k].img} alt={BLOG_DATA[k].title}/>
                            </Link>

                            <div className={'blog-item-date flex just-space align-center w-full'}>
                                <span>Блог</span>
                                <span>{BLOG_DATA[k].date}</span>
                            </div>
                            <div className={'blog-item-title'} dangerouslySetInnerHTML={{__html: BLOG_DATA[k].title}}></div>
                           {/* <div className={'blog-item-hashtag'}>
                                <div className="text">{BLOG_DATA[k].hashtag}</div>
                            </div>*/}
                        </div>
                    )
                })
            }
        </div>
    );
};

export default Blog;
