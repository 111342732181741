import React from 'react';

const Screw = () => {
    return (
        <svg className={'screw-icon'} width="76" height="87" viewBox="0 0 76 87" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.67">
                <path
                    d="M39.2247 86.0972C38.6254 86.4433 37.8869 86.4433 37.2876 86.0972L1.65341 65.5239C1.05406 65.1778 0.684839 64.5384 0.684839 63.8463L0.68483 22.6996C0.68483 22.0074 1.05405 21.368 1.6534 21.0219L37.2876 0.448557C37.8869 0.102516 38.6254 0.102516 39.2247 0.448557L74.8588 21.0219C75.4582 21.368 75.8274 22.0074 75.8274 22.6995V63.8462C75.8274 64.5384 75.4582 65.1778 74.8588 65.5239L39.2247 86.0972Z"
                    fill="#DBEFD7"/>
                <path
                    d="M38.256 22.0363C26.5274 22.0363 17.0194 31.5443 17.0194 43.2729C17.0194 55.0016 26.5274 64.5095 38.256 64.5095C49.9847 64.5095 59.4926 55.0016 59.4926 43.2729C59.4926 31.5443 49.9847 22.0363 38.256 22.0363Z"
                    fill="white"/>
            </g>
        </svg>
    );
};

export default Screw;
