import React from 'react';

const AdvArrow = () => {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M13.2278 2.2747H1.2417V0.369995H15.527C16.053 0.369995 16.4793 0.796378 16.4793 1.32235V15.6076H14.5746V3.62153L2.10245 16.0937L0.755615 14.7469L13.2278 2.2747Z"
                  fill="#DCEFD8"/>
        </svg>
    );
};

export default AdvArrow;
