import React from 'react';

const AdvThree = () => {
    return (
        <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M32.8793 26.2998C36.7309 24.8289 41.0747 25.5247 44.2744 28.1249C47.474 30.725 49.0434 34.8346 48.3913 38.9057C47.7393 42.9768 44.9649 46.3907 41.1133 47.8616C37.2617 49.3325 32.9179 48.6368 29.7182 46.0366C26.5186 43.4364 24.9492 39.3267 25.6013 35.2557C26.2533 31.1846 29.0277 27.7706 32.8793 26.2998Z"
                fill="#97D28B" fillOpacity="0.1"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M33.6619 28.349C30.5424 29.5403 28.2953 32.3053 27.7672 35.6026C27.2391 38.8998 28.5102 42.2283 31.1017 44.3342C33.6931 46.4402 37.2112 47.0036 40.3307 45.8124C43.4503 44.621 45.6973 41.856 46.2254 38.5588C46.7535 35.2616 45.4824 31.9332 42.8909 29.8272C40.2995 27.7212 36.7814 27.1578 33.6619 28.349ZM44.2744 28.1249C41.0747 25.5247 36.7309 24.8289 32.8793 26.2998C29.0277 27.7706 26.2533 31.1846 25.6013 35.2557C24.9492 39.3267 26.5186 43.4364 29.7182 46.0366C32.9179 48.6368 37.2617 49.3325 41.1133 47.8616C44.9649 46.3907 47.7393 42.9768 48.3913 38.9057C49.0434 34.8346 47.474 30.725 44.2744 28.1249Z"
                  fill="#16ECFB"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M28.9871 16.0931C21.4889 18.9565 16.0879 25.6026 14.8186 33.528C13.5492 41.4533 16.6044 49.4538 22.8333 54.5158C29.0622 59.5777 37.5184 60.932 45.0166 58.0686C52.5148 55.2053 57.9158 48.5591 59.1852 40.6338C60.4545 32.7084 57.3993 24.708 51.1705 19.6461C44.9416 14.5841 36.4853 13.2297 28.9871 16.0931ZM52.5539 17.9437C45.7168 12.3875 36.4349 10.9008 28.2045 14.0438C19.9742 17.1867 14.0457 24.4819 12.6526 33.181C11.2593 41.8802 14.6127 50.662 21.4498 56.2182C28.2869 61.7744 37.5688 63.2609 45.7992 60.118C54.0296 56.975 59.9579 49.6799 61.3512 40.9807C62.7445 32.2815 59.391 23.4999 52.5539 17.9437Z"
                  fill="#16ECFB"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M24.8666 5.29909C13.512 9.63511 5.3333 19.6993 3.41116 31.7006C1.48901 43.7019 6.11549 55.817 15.5478 63.4823C24.9802 71.1475 37.7854 73.1985 49.14 68.8625C60.4945 64.5264 68.6732 54.4622 70.5953 42.4609C72.5175 30.4596 67.8909 18.3445 58.4586 10.6792C49.0262 3.01397 36.2211 0.963067 24.8666 5.29909ZM59.8421 8.97685C49.8016 0.817307 36.1706 -1.36584 24.084 3.24977C11.9973 7.86539 3.29119 18.5786 1.24511 31.3537C-0.800979 44.1289 4.12382 57.0252 14.1644 65.1847C24.205 73.3442 37.8358 75.5274 49.9226 70.9117C62.0092 66.2961 70.7153 55.5829 72.7614 42.8078C74.8074 30.0326 69.8827 17.1363 59.8421 8.97685Z"
                  fill="#16ECFB"/>
        </svg>
    );
};

export default AdvThree;
