import React, {useEffect} from 'react';
import HeaderMenu from "../../components/Header/HeaderMenu";
import Footer from "../../components/Footer/Footer";
import {useParams} from "react-router-dom";
import {ABOUT} from "../../utils/mock";

const SingleHistory = () => {

    const params = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const renderContent = () => {
        const hash = params.hash;
        if (hash) {
            return ABOUT[hash]
        }
    }

    return (
        <div className={'flex flex-column gap-24'}>
            <div className={'main-container'}>
                <div className={'header relative'}>
                    <HeaderMenu/>
                </div>
            </div>
            <div className={'main-container'}>
                <div className={'flex flex-column gap-24'}>
                    <h1 className={'adv-title page-title'}>
                        {renderContent().title}
                    </h1>
                    <div dangerouslySetInnerHTML={{__html: renderContent().content}}>

                    </div>
                </div>
            </div>
            <div className={'main-container relative mt-auto'}>
            <Footer/>
            </div>
        </div>
    );
};

export default SingleHistory;
