import React from 'react';

const BenefitList = () => {

    const clickHandler = (e) => {
        const child = e.currentTarget?.querySelector('.benefit-collapse-content');
        if (!child) return;

        if (child.classList.contains('is-active')) {
            child.classList.remove('is-active');
            child.style.maxHeight = '0';
            e.currentTarget.classList.remove('is-opened');
        } else {
            child.classList.add('is-active');
            child.style.maxHeight = child.scrollHeight + 'px';
            e.currentTarget.classList.add('is-opened');
        }
    }

    return (
        <div className={'benefit-list'}>
            <div className={'benefit-list-item flex flex-column'} onClick={clickHandler}>
                <div className={'flex gap-16 just-space align-center'}>
                    <div className={'benefit-list-item-title'}>
                        Высокая доходность
                    </div>
                    <div className="plus"></div>
                </div>
                <div className={'benefit-collapse-content'}>
                    <span/>
                    <h2 className={'benefit-subtitle'}>Превышение среднерыночных показателей</h2>
                    <p>Долговые обязательства TradeChangePlace OÜ предлагают доходность, значительно превышающую ставки
                        по банковским депозитам и другим традиционным инвестиционным инструментам.</p>

                    <h2 className={'benefit-subtitle'}>Доходность в рублях:</h2>
                    <ul>
                        <li>Доходность от 18% годовых при вложениях на 6-12 месяцев.</li>
                        <li>Доходность от 20% годовых при вложениях на 1-2 года.</li>
                        <li>Доходность от 20% годовых с компенсацией инфляции при вложениях на 2-3 года.</li>
                        <li>Доходность от 25% годовых при вложениях на срок свыше 3 лет.</li>
                    </ul>

                    <h2 className={'benefit-subtitle'}>Доходность в евро:</h2>
                    <ul>
                        <li>Доходность от 14% годовых при вложениях на 6-12 месяцев.</li>
                        <li>Доходность от 16% годовых при вложениях на 1-2 года.</li>
                        <li>Доходность от 16% годовых с компенсацией инфляции при вложениях на 2-3 года.</li>
                        <li>Доходность от 20% годовых при вложениях на срок свыше 3 лет.</li>
                    </ul>

                    <h2 className={'benefit-subtitle'}>Гарантии и обеспечение:</h2>
                    <p>Долговые обязательства TradeChangePlace OÜ на 100% обеспечены в специальном фонде банка, что
                        гарантирует надежность и стабильность выплат.</p>

                    <h2 className={'benefit-subtitle'}>Преимущества краткосрочных и долгосрочных вложений:</h2>
                    <p>Возможность выбора различных сроков инвестирования с соответствующей доходностью, позволяя
                        инвесторам гибко управлять своими сбережениями и получать высокий доход.</p>

                    <h2 className={'benefit-subtitle'}>Прозрачность и регулярные отчеты:</h2>
                    <p>Полная прозрачность всех операций и предоставление регулярных отчетов о состоянии инвестиций, что
                        обеспечивает доверие и уверенность инвесторов.</p>

                    <h2 className={'benefit-subtitle'}>Налогообложение:</h2>
                    <p>Долговые обязательства не являются ценными бумагами и подлежат налогообложению в соответствии с
                        законодательством РФ. Убедитесь в том, что все налоговые обязательства выполняются в
                        соответствии с действующими нормами Налогового кодекса РФ.</p>
                </div>
            </div>
            <div className={'benefit-list-item flex flex-column'} onClick={clickHandler}>
                <div className={'flex gap-16 just-space align-center'}>
                    <div className={'benefit-list-item-title'}>
                        Гибкость
                    </div>
                    <div className="plus"></div>
                </div>
                <div className={'benefit-collapse-content'}>
                    <span/>
                    <h2 className={'benefit-subtitle'}>Различные сроки инвестирования</h2>
                    <p>Возможность выбора сроков инвестирования от 6 месяцев до более чем 3 лет, что позволяет
                        инвесторам планировать свои вложения в зависимости от финансовых целей.</p>

                    <h2 className={'benefit-subtitle'}>Погашение в любой момент</h2>
                    <p>Возможность погашения долговых обязательств в любой момент онлайн с зачислением средств на ваш
                        банковский счет, обеспечивая быстрый доступ к деньгам.</p>

                    <h2 className={'benefit-subtitle'}>Выбор валюты</h2>
                    <p>Инвестирование как в рублях, так и в евро, что позволяет диверсифицировать риски и выбирать
                        наиболее удобную валюту для вложений.</p>

                    <h2 className={'benefit-subtitle'}>Именные сертификаты</h2>
                    <p>Оформление каждого долгового обязательства именным сертификатом, подтверждающим права и
                        обязательства сторон, что упрощает управление инвестициями.</p>

                    <h2 className={'benefit-subtitle'}>Удобство управления</h2>
                    <p>Полная прозрачность всех операций и регулярные отчеты о состоянии инвестиций, что позволяет
                        инвесторам легко отслеживать и управлять своими вложениями.</p>

                    <h2 className={'benefit-subtitle'}>Онлайн доступ</h2>
                    <p>Доступ ко всей информации и управлению инвестициями через удобный онлайн-портал, обеспечивающий
                        круглосуточное обслуживание и поддержку.</p>

                    <h2 className={'benefit-subtitle'}>Регулярные выплаты</h2>
                    <p>Возможность получения регулярных выплат процентов по долговым обязательствам, что обеспечивает
                        постоянный доход и гибкость в планировании личного бюджета.</p>
                </div>
            </div>
            <div className={'benefit-list-item flex flex-column'} onClick={clickHandler}>
                <div className={'flex gap-16 just-space align-center'}>
                    <div className={'benefit-list-item-title'}>
                        Ежемесячные доходы
                    </div>
                    <div className="plus"></div>
                </div>
                <div className={'benefit-collapse-content'}>
                    <span/>
                    <h2 className={'benefit-subtitle'}>Регулярные выплаты процентов</h2>
                    <p>Возможность получения ежемесячных процентных выплат по долговым обязательствам, обеспечивая
                        стабильный денежный поток.</p>

                    <h2 className={'benefit-subtitle'}>Постоянный доход</h2>
                    <p>Обеспечение постоянного дохода, что позволяет лучше планировать личный бюджет и покрывать текущие
                        расходы.</p>

                    <h2 className={'benefit-subtitle'}>Прозрачные расчеты</h2>
                    <p>Полная прозрачность всех операций и предоставление регулярных отчетов о состоянии ваших
                        инвестиций и выплат, что способствует доверительному отношению.</p>

                    <h2 className={'benefit-subtitle'}>Онлайн доступ</h2>
                    <p>Удобный онлайн-доступ к информации о выплаченных процентах и состоянии ваших инвестиций в любое
                        время.</p>

                    <h2 className={'benefit-subtitle'}>Учет инфляции</h2>
                    <p>Включение в расчет процентов компенсации инфляции, что помогает сохранить реальную стоимость
                        вашего дохода.</p>

                    <h2 className={'benefit-subtitle'}>Гибкие условия</h2>
                    <p>Возможность выбора различных сроков инвестирования с ежемесячными выплатами, что позволяет
                        инвесторам гибко планировать свои финансовые потоки.</p>

                    <h2 className={'benefit-subtitle'}>Простота получения средств</h2>
                    <p>Легкость в получении ежемесячных выплат на ваш банковский счет, что обеспечивает быстрый и
                        удобный доступ к доходам.</p>
                </div>
            </div>
            <div className={'benefit-list-item flex flex-column'} onClick={clickHandler}>
                <div className={'flex gap-16 just-space align-center'}>
                    <div className={'benefit-list-item-title'}>
                        Компенсация инфляции
                    </div>
                    <div className="plus"></div>
                </div>
                <div className={'benefit-collapse-content'}>
                    <span/>
                    <h2 className={'benefit-subtitle'}>Сохранение покупательной способности</h2>
                    <p>Включение механизма компенсации инфляции в процентные ставки долговых обязательств, что помогает
                        сохранить реальную стоимость ваших вложений.</p>

                    <h2 className={'benefit-subtitle'}>Защита от обесценивания</h2>
                    <p>Обеспечение стабильного дохода, учитывающего инфляционные процессы, что защищает ваши инвестиции
                        от обесценивания.</p>

                    <h2 className={'benefit-subtitle'}>Привязка к индексам инфляции</h2>
                    <p>Регулярная корректировка процентных ставок в соответствии с актуальными индексами инфляции, что
                        гарантирует адекватную компенсацию.</p>

                    <h2 className={'benefit-subtitle'}>Повышенная доходность</h2>
                    <p>Предложение более высоких процентных ставок для долгосрочных вложений, что включает компенсацию
                        инфляции и обеспечивает более выгодные условия инвестирования.</p>

                    <h2 className={'benefit-subtitle'}>Прозрачные расчеты</h2>
                    <p>Полная прозрачность всех расчетов и регулярное предоставление отчетов, демонстрирующих, как
                        инфляция влияет на ваши доходы и выплаты.</p>

                    <h2 className={'benefit-subtitle'}>Устойчивый реальный доход</h2>
                    <p>Гарантия устойчивого реального дохода, независимо от колебаний инфляции, что делает инвестиции
                        надежными и предсказуемыми.</p>

                    <h2 className={'benefit-subtitle'}>Инвестирование в стабильные активы</h2>
                    <p>Инвестирование в долговые обязательства, которые привязаны к стабильным активам и имеют поддержку
                        надежного обеспечительного фонда, что дополнительно компенсирует инфляционные риски.</p>
                </div>
            </div>
            <div className={'benefit-list-item flex flex-column'} onClick={clickHandler}>
                <div className={'flex gap-16 just-space align-center'}>
                    <div className={'benefit-list-item-title'}>
                        Погашение за границей
                    </div>
                    <div className="plus"></div>
                </div>
                <div className={'benefit-collapse-content'}>
                    <span/>
                    <h2 className={'benefit-subtitle'}>Международная ликвидность</h2>
                    <p>Возможность погашения долговых обязательств за границей, обеспечивая доступ к инвестициям в
                        международных рынках.</p>

                    <h2 className={'benefit-subtitle'}>Глобальная доступность</h2>
                    <p>Инвесторы могут получать свои выплаты в любом месте мира, что особенно удобно для тех, кто часто
                        путешествует или проживает за границей.</p>

                    <h2 className={'benefit-subtitle'}>Валютная гибкость</h2>
                    <p>Возможность конвертации долговых обязательств в различные валюты при погашении, что помогает
                        управлять валютными рисками и диверсифицировать портфель.</p>

                    <h2 className={'benefit-subtitle'}>Удобство и простота</h2>
                    <p>Процесс погашения долговых обязательств за границей является удобным и простым, без необходимости
                        сложных бюрократических процедур.</p>

                    <h2 className={'benefit-subtitle'}>Поддержка международных банков</h2>
                    <p>Погашение долговых обязательств осуществляется через надежные международные банки, что
                        обеспечивает безопасность и надежность транзакций.</p>

                    <h2 className={'benefit-subtitle'}>Низкие комиссии</h2>
                    <p>Минимальные комиссии за международные переводы и погашение долговых обязательств, что делает
                        процесс экономически выгодным.</p>

                    <h2 className={'benefit-subtitle'}>Регулярные выплаты</h2>
                    <p>Возможность получения регулярных выплат процентов и основного долга на иностранные банковские
                        счета, что обеспечивает постоянный доход и доступ к средствам в любой точке мира.</p>
                </div>
            </div>
            <div className={'benefit-list-item flex flex-column'} onClick={clickHandler}>
                <div className={'flex gap-16 just-space align-center'}>
                    <div className={'benefit-list-item-title'}>
                        Платежное средство
                    </div>
                    <div className="plus"></div>
                </div>
                <div className={'benefit-collapse-content'}>
                    <span/>
                    <h2 className={'benefit-subtitle'}>Высокая ликвидность</h2>
                    <p>Долговые обязательства TradeChangePlace OÜ можно быстро конвертировать в наличные средства или
                        перевести на банковский счет, что обеспечивает высокую ликвидность.</p>

                    <h2 className={'benefit-subtitle'}>Универсальность использования</h2>
                    <p>Возможность использовать долговые обязательства в качестве платежного средства для различных
                        финансовых операций и покупок.</p>

                    <h2 className={'benefit-subtitle'}>Использование на маркетплейсе TCP-MARKET</h2>
                    <p>Долговые обязательства используются как платежный инструмент на маркетплейсе TCP-MARKET компании
                        TradeChangePlace OÜ.</p>

                    <h2 className={'benefit-subtitle'}>Безопасность транзакций</h2>
                    <p>Все операции с долговыми обязательствами проходят через платежную систему TCP-MARKET и другие
                        надежные и проверенные платежные системы, что обеспечивает безопасность и защиту средств.</p>

                    <h2 className={'benefit-subtitle'}>Минимальные комиссии</h2>
                    <p>Низкие комиссии за транзакции с долговыми обязательствами делают их использование экономически
                        выгодным.</p>

                    <h2 className={'benefit-subtitle'}>Простота использования</h2>
                    <p>Интуитивно понятный процесс совершения платежей и переводов с использованием долговых
                        обязательств, доступный как для физических, так и для юридических лиц.</p>

                    <h2 className={'benefit-subtitle'}>Поддержка различных валют</h2>
                    <p>Возможность конвертации долговых обязательств в различные валюты, что позволяет удобно совершать
                        международные платежи.</p>

                    <h2 className={'benefit-subtitle'}>Удобство онлайн-операций</h2>
                    <p>Возможность совершать платежи и переводы онлайн, обеспечивая быстрый и удобный доступ к средствам
                        в любое время и из любой точки мира.</p>
                </div>
            </div>
            <div className={'benefit-list-item flex flex-column'} onClick={clickHandler}>
                <div className={'flex gap-16 just-space align-center'}>
                    <div className={'benefit-list-item-title'}>
                        Контроль
                    </div>
                    <div className="plus"></div>
                </div>
                <div className={'benefit-collapse-content'}>
                    <span/>
                    <h2 className={'benefit-subtitle'}>Полная прозрачность операций:</h2>
                    <p>Вся информация о сделках и состоянии инвестиций доступна в любое время, что
                        обеспечивает полный контроль над вашими средствами.
                    </p>
                    <h2 className={'benefit-subtitle'}>Регулярные отчеты:</h2>
                    <p>Инвесторы получают регулярные отчеты о своих инвестициях, что позволяет отслеживать
                        доходность и состояние долговых обязательств.
                    </p>
                    <h2 className={'benefit-subtitle'}>Онлайн мониторинг:</h2>

                    <p>Удобная онлайн-платформа для мониторинга и управления инвестициями в режиме
                        реального времени.
                    </p>

                    <h2 className={'benefit-subtitle'}>Уведомления и оповещения:</h2>

                    <p>Получение уведомлений о ключевых событиях и операциях с долговыми обязательствами,
                        что помогает быть в курсе всех изменений.
                    </p>

                    <h2 className={'benefit-subtitle'}>Доступ к истории операций:</h2>

                    <p>Возможность просматривать историю всех транзакций и операций с долговыми
                        обязательствами, что обеспечивает прозрачность и контроль.
                    </p>

                    <h2 className={'benefit-subtitle'}>Аудит и соответствие требованиям:</h2>

                    <p>Регулярный аудит и проверка соответствия законодательным требованиям, что
                        гарантирует законность и надежность операций.
                    </p>

                    <h2 className={'benefit-subtitle'}>Персонализированные настройки:</h2>

                    <p>Возможность настраивать параметры уведомлений и отчетов в соответствии с
                        индивидуальными предпочтениями инвестора.
                    </p>

                    <h2 className={'benefit-subtitle'}>Поддержка и консультации:</h2>

                    <p>Круглосуточная поддержка и возможность получения консультаций от специалистов, что
                        помогает эффективно управлять инвестициями и принимать обоснованные решения.
                    </p>

                </div>
            </div>
        </div>
    );
};

export default BenefitList;
