import React from 'react';
import HeaderMenu from "../../components/Header/HeaderMenu";
import Footer from "../../components/Footer/Footer";

const Offer = () => {
    return (
        <div className={'flex flex-column gap-24'}>
            <div className={'main-container'}>
                <div className={'header relative'}>
                    <HeaderMenu/>
                </div>
            </div>
            <div className={'main-container'}>
                <div className={'flex flex-column gap-24'}>
                    <h1 className={'adv-title page-title'}>Публичная оферта на продажу долговых обязательств TCPcredit</h1>
                    <section>
                        <h2>1. Введение</h2>

                        <p>1.1. Определение сторон договора</p>
                        <ul>
                            <li><strong>Должник</strong>: <strong>TradeChangePlace OÜ</strong></li>
                            <ul>
                                <li><strong>Адрес</strong>: Harju maakond, Tallinn, Kesklinna linнаоса, Tuukри tn
                                    19-315, 10120
                                </li>
                                <li><strong>Регистрационный номер</strong>: 14789801</li>
                            </ul>
                            <li><strong>Агент</strong>: <strong>ИНДИВИДУАЛЬНЫЙ ПРЕДПРИНИМАТЕЛЬ РЕЗНИКОВА ЕКАТЕРИНА
                                ВАДИМОВНА</strong></li>
                            <ul>
                                <li><strong>ИНН</strong>: 771889347043</li>
                                <li><strong>Расчётный счёт</strong>: 40802810163660000103</li>
                                <li><strong>Банк</strong>: РФ АО "Россельхозбанк" - "ЦРМБ"</li>
                                <li><strong>БИК</strong>: 044525430</li>
                            </ul>
                            <li><strong>Покупатель/Инвестор</strong>: физическое или юридическое лицо, предоставляющее
                                контактные данные, необходимые для идентификации и выполнения обязательств по договору,
                                включая полное наименование для юридических лиц или ФИО для физических лиц, ИНН, адрес,
                                телефон и электронную почту.
                            </li>
                        </ul>

                        <p>1.2. Основные термины и определения</p>
                        <ul>
                            <li><strong>Оферта</strong>: Настоящая публичная оферта на продажу долговых обязательств
                                TCPcredit.
                            </li>
                            <li><strong>Акцепт</strong>: Полное и безоговорочное принятие условий оферты
                                покупателем/инвестором.
                            </li>
                            <li><strong>Долговые обязательства TCPcredit</strong>: Обязательства, не являющиеся ценными
                                бумагами, выпускаемые TradeChangePlace OÜ.
                            </li>
                            <li><strong>Договор</strong>: Договор на продажу долговых обязательств TCPcredit,
                                заключаемый между TradeChangePlace OÜ, агентом и покупателем/инвестором на условиях
                                оферты.
                            </li>
                        </ul>

                        <h2>2. Предмет договора</h2>

                        <p>2.1. <strong>Описание долговых обязательств TCPcredit</strong></p>
                        <ul>
                            <li>Долговые обязательства не являются ценными бумагами и не требуют регистрации или
                                лицензирования со стороны финансового регулятора в соответствии с Федеральным законом "О
                                рынке ценных бумаг" № 39-ФЗ.
                            </li>
                        </ul>

                        <p>2.2. <strong>Условия выпуска и обращения долговых обязательств</strong></p>
                        <ul>
                            <li>Долговые обязательства выпускаются в цифровой форме и размещаются в сети блокчейн TRON,
                                что обеспечивает их безопасность и неизменность.
                            </li>
                        </ul>

                        <p>2.3. <strong>Объем выпуска</strong></p>
                        <ul>
                            <li>Обязательства возникли вследствие заемных отношений между TradeChangePlace OÜ и Sushi
                                Talo Kerava Ky, в рамках которых TradeChangePlace OÜ привлекла заемные средства в
                                размере 4.800.000 евро.
                            </li>
                            <li>Счет в банке:</li>
                            <ul>
                                <li><strong>Банк</strong>: Op Bank</li>
                                <li><strong>Адрес</strong>: Gebhardinaukio 1, 00510 Helsinki, Finland</li>
                                <li><strong>IBAN</strong>: FI39 5092 0920 3572 07</li>
                                <li><strong>BIC</strong>: OKOYFIHH</li>
                            </ul>
                            <li><strong>Кредитор</strong>: Sushi Talo Kerava Ky</li>
                            <ul>
                                <li><strong>Адрес</strong>: Tornipolku 2B200, 06400 Porvoo, Finland</li>
                            </ul>
                        </ul>

                        <h2>3. Порядок заключения договора</h2>

                        <p>3.1. <strong>Акцепт оферты</strong></p>
                        <ul>
                            <li>В соответствии с Гражданским кодексом РФ (ст. 438), акцептом оферты является выполнение
                                покупателем/инвестором действий, выражающих его согласие с условиями оферты, а именно,
                                совершение платежа.
                            </li>
                        </ul>

                        <p>3.2. <strong>Момент заключения договора</strong></p>
                        <ul>
                            <li>В соответствии с Гражданским кодексом РФ (ст. 433), договор считается заключенным с
                                момента получения акцепта оферты должником или агентом.
                            </li>
                        </ul>

                        <p>3.3. <strong>Способы акцепта</strong></p>
                        <ul>
                            <li>Акцепт может быть выражен путем оплаты долговых обязательств TCPcredit на указанные
                                реквизиты.
                            </li>
                        </ul>

                        <h2>4. Права и обязанности сторон</h2>

                        <p>4.1. <strong>Права и обязанности должника (TradeChangePlace OÜ)</strong></p>
                        <ul>
                            <li>Должник обязуется своевременно и в полном объеме выплачивать доход по долговым
                                обязательствам.
                            </li>
                            <li>Должник обязуется предоставлять покупателю/инвестору информацию о состоянии
                                инвестиций.
                            </li>
                        </ul>

                        <p>4.2. <strong>Права и обязанности агента (ИП Резникова)</strong></p>
                        <ul>
                            <li>Агент обязуется продавать долговые обязательства на территории РФ и предоставлять
                                покупателям необходимую информацию.
                            </li>
                            <li>Агент обязуется подавать сведения о покупателях в ФНС России, а также все сведения о
                                полученных покупателем долговых обязательствах, дивидендах и сведениях о погашении
                                долговых обязательств в РФ и за ее пределами для строгой отчетности.
                            </li>
                            <li>Агент оставляет за собой право отказать в продаже долговых обязательств без объяснения
                                причин, в том числе, если предоставленные покупателем данные окажутся недостоверными или
                                неполными, либо если существует подозрение на нарушение законодательства РФ.
                            </li>
                        </ul>

                        <p>4.3. <strong>Права и обязанности покупателя/инвестора</strong></p>
                        <ul>
                            <li>Покупатель/Инвестор обязуется своевременно оплачивать приобретенные долговые
                                обязательства.
                            </li>
                            <li>Покупатель/Инвестор обязуется предоставлять агенту все необходимые данные для выполнения
                                обязательств по договору, включая полные контактные данные для идентификации.
                            </li>
                            <li>Покупатель/Инвестор обязуется самостоятельно декларировать полученные доходы от долговых
                                обязательств и уплачивать налоги в соответствии с налоговым законодательством РФ.
                            </li>
                        </ul>

                        <h2>5. Цена и порядок расчетов</h2>

                        <p>5.1. <strong>Стоимость долговых обязательств</strong></p>
                        <ul>
                            <li>Стоимость одной единицы долговых обязательств TCPcredit равна 1 доллару США, исчисляемых
                                в евро по курсу банка РФ.
                            </li>
                        </ul>

                        <p>5.2. <strong>Порядок и способы оплаты</strong></p>
                        <ul>
                            <li>Оплата производится в рублях на территории РФ на расчетный счет агента и в евро на
                                территории ЕС на расчетный счет, указанный агентом. Порядок оплаты регулируется
                                Гражданским кодексом РФ (ст. 486).
                            </li>
                        </ul>

                        <p>5.3. <strong>Валюта расчетов</strong></p>
                        <ul>
                            <li>Расчеты в рублях производятся на территории РФ на расчетный счет агента, расчеты в евро
                                производятся на территории ЕС на расчетный счет, указанный агентом.
                            </li>
                        </ul>

                        <p>5.4. <strong>Условия и порядок возврата денежных средств</strong></p>
                        <ul>
                            <li>Возврат денежных средств возможен в случае отказа от сделки до момента завершения
                                платежа.
                            </li>
                        </ul>

                        <h2>6. Условия размещения и погашения долговых обязательств</h2>

                        <p>6.1. <strong>Сроки размещения</strong></p>
                        <ul>
                            <li>Сроки размещения оговариваются в индивидуальном порядке.</li>
                        </ul>

                        <p>6.2. <strong>Условия и порядок погашения</strong></p>
                        <ul>
                            <li>Обеспечение погашения долговых обязательств осуществляется со счета компании ИП на
                                территории РФ по распоряжению компании TradeChangePlace OÜ.
                            </li>
                        </ul>

                        <p>6.3. <strong>Процентные ставки и выплаты дохода</strong></p>
                        <ul>
                            <li>Процентная ставка составляет от 16% годовых в евро и от 20% годовых в рублях. Выплаты
                                производятся в валюте расчетов.
                            </li>
                        </ul>

                        <h2>7. Гарантии и качество</h2>

                        <p>7.1. <strong>Гарантийные обязательства должника</strong></p>
                        <ul>
                            <li>Должник обязуется своевременно и в полном объеме выплачивать доход по долговым
                                обязательствам.
                            </li>
                        </ul>

                        <p>7.2. <strong>Гарантийные обязательства агента</strong></p>
                        <ul>
                            <li>Агент обязуется предоставлять покупателям точную и актуальную информацию, соблюдать
                                условия продажи и обеспечивать правомерность всех операций.
                            </li>
                        </ul>

                        <p>7.3. <strong>Порядок предъявления претензий</strong></p>
                        <ul>
                            <li>Покупатель/Инвестор вправе предъявить претензии в случае нарушения условий договора.
                                Претензии рассматриваются в порядке, установленном законодательством РФ.
                            </li>
                        </ul>

                        <p>7.4. <strong>Ответственность за качество долговых обязательств</strong></p>
                        <ul>
                            <li>Должник и агент несут ответственность за качество и надежность долговых обязательств.
                            </li>
                        </ul>

                        <h2>8. Ответственность сторон</h2>

                        <p>8.1. <strong>Общие положения об ответственности</strong></p>
                        <ul>
                            <li>Все стороны договора несут ответственность за выполнение своих обязательств в
                                соответствии с условиями договора и действующим законодательством.
                            </li>
                        </ul>

                        <p>8.2. <strong>Ответственность за нарушение сроков</strong></p>
                        <ul>
                            <li>В случае нарушения сроков выполнения обязательств стороны несут ответственность в
                                соответствии с условиями договора и действующим законодательством.
                            </li>
                        </ul>

                        <p>8.3. <strong>Ответственность за качество долговых обязательств</strong></p>
                        <ul>
                            <li>Должник и агент несут ответственность за качество предоставляемых долговых
                                обязательств.
                            </li>
                        </ul>

                        <p>8.4. <strong>Ограничение ответственности</strong></p>
                        <ul>
                            <li>Ответственность сторон ограничивается условиями договора и применимым
                                законодательством.
                            </li>
                        </ul>

                        <h2>9. Условия расторжения договора</h2>

                        <p>9.1. <strong>Основания для расторжения договора</strong></p>
                        <ul>
                            <li>Договор может быть расторгнут по взаимному согласию сторон или в случае нарушения
                                условий одной из сторон.
                            </li>
                        </ul>

                        <p>9.2. <strong>Порядок расторжения договора</strong></p>
                        <ul>
                            <li>Расторжение договора производится в письменной форме.</li>
                        </ul>

                        <p>9.3. <strong>Последствия расторжения договора</strong></p>
                        <ul>
                            <li>В случае расторжения договора стороны обязуются урегулировать все взаимные
                                обязательства.
                            </li>
                        </ul>

                        <h2>10. Конфиденциальность</h2>

                        <p>10.1. <strong>Обязанности сторон по сохранению конфиденциальности</strong></p>
                        <ul>
                            <li>Стороны обязуются сохранять конфиденциальность информации, полученной в рамках
                                выполнения договора.
                            </li>
                        </ul>

                        <p>10.2. <strong>Порядок использования конфиденциальной информации</strong></p>
                        <ul>
                            <li>Конфиденциальная информация может быть использована только в целях выполнения условий
                                договора.
                            </li>
                        </ul>

                        <p>10.3. <strong>Исключения из обязанностей по конфиденциальности</strong></p>
                        <ul>
                            <li>Исключения могут быть предусмотрены законодательством или соглашением сторон.</li>
                        </ul>

                        <h2>11. Форс-мажор</h2>

                        <p>11.1. <strong>Определение форс-мажорных обстоятельств</strong></p>
                        <ul>
                            <li>Форс-мажорные обстоятельства включают в себя события, не зависящие от воли сторон,
                                которые делают невозможным или значительно затрудняют выполнение обязательств по
                                договору. К таким обстоятельствам относятся: стихийные бедствия, пожары, наводнения,
                                землетрясения, военные действия, акты терроризма, гражданские беспорядки, изменения
                                законодательства и другие события, признанные форс-мажорными обстоятельствами.
                            </li>
                        </ul>

                        <p>11.2. <strong>Порядок действий при наступлении форс-мажора</strong></p>
                        <ul>
                            <li>Сторона, для которой выполнение обязательств стало невозможным вследствие форс-мажорных
                                обстоятельств, обязана в течение 10 (десяти) дней с момента их наступления уведомить
                                другую сторону о таких обстоятельствах и их влиянии на выполнение обязательств по
                                договору.
                            </li>
                        </ul>

                        <p>11.3. <strong>Освобождение от ответственности в случае форс-мажора</strong></p>
                        <ul>
                            <li>Стороны освобождаются от ответственности за невыполнение обязательств по договору в
                                случае наступления форс-мажорных обстоятельств, если такие обстоятельства
                                непосредственно повлияли на выполнение обязательств. При этом сроки выполнения
                                обязательств по договору могут быть продлены на период действия форс-мажорных
                                обстоятельств и времени, необходимого для устранения их последствий.
                            </li>
                        </ul>

                        <h2>12. Порядок разрешения споров</h2>

                        <p>12.1. <strong>Претензионный порядок урегулирования споров</strong></p>
                        <ul>
                            <li>Все споры и разногласия, возникающие в связи с исполнением настоящего договора, подлежат
                                урегулированию путем переговоров между сторонами. Сторона, считающая свои права
                                нарушенными, направляет другой стороне письменную претензию с указанием существа спора и
                                своих требований.
                            </li>
                        </ul>

                        <p>12.2. <strong>Арбитраж и юрисдикция</strong></p>
                        <ul>
                            <li>В случае невозможности урегулирования споров путем переговоров, споры подлежат
                                рассмотрению в арбитражном суде по месту нахождения должника, если иное не предусмотрено
                                законодательством.
                            </li>
                        </ul>

                        <p>12.3. <strong>Применимое право</strong></p>
                        <ul>
                            <li>К договору применяется законодательство Российской Федерации.</li>
                        </ul>

                        <h2>13. Прочие условия</h2>

                        <p>13.1. <strong>Внесение изменений и дополнений в договор</strong></p>
                        <ul>
                            <li>Все изменения и дополнения к договору должны быть оформлены в письменной форме и
                                подписаны сторонами.
                            </li>
                        </ul>

                        <p>13.2. <strong>Срок действия договора</strong></p>
                        <ul>
                            <li>Договор вступает в силу с момента акцепта оферты и действует до полного выполнения
                                сторонами своих обязательств.
                            </li>
                        </ul>

                        <p>13.3. <strong>Порядок передачи прав и обязанностей по договору</strong></p>
                        <ul>
                            <li>
                                Передача прав и обязанностей по договору третьим лицам возможна только с письменного
                                согласия другой стороны.
                            </li>
                        </ul>

                        <h2>14. Реквизиты и подписи сторон</h2>

                        <p>14.1. <strong>Реквизиты должника</strong></p>
                        <ul>
                            <li><strong>TradeChangePlace OÜ</strong></li>
                            <li><strong>Адрес</strong>: Harju maakond, Tallinn, Kesklinna linнаоса, Tuukри tn 19-315,
                                10120
                            </li>
                            <li><strong>Регистрационный номер</strong>: 14789801</li>
                        </ul>

                        <p>14.2. <strong>Реквизиты агента</strong></p>
                        <ul>
                            <li><strong>ИНДИВИДУАЛЬНЫЙ ПРЕДПРИНИМАТЕЛЬ РЕЗНИКОВА ЕКАТЕРИНА ВАДИМОВНА</strong></li>
                            <li><strong>ИНН</strong>: 771889347043</li>
                            <li><strong>Расчётный счёт</strong>: 40802810163660000103</li>
                            <li><strong>Банк</strong>: РФ АО "Россельхозбанк" - "ЦРМБ"</li>
                            <li><strong>БИК</strong>: 044525430</li>
                        </ul>

                        <p>14.3. <strong>Реквизиты покупателя/инвестора</strong></p>
                        <p>Покупатель/Инвестор обязан указать свои контактные данные для идентификации, включая полное
                            наименование для юридических лиц или ФИО для физических лиц, ИНН, адрес, телефон и
                            электронную почту.</p>

                        <p>14.4. <strong>Подписи сторон</strong></p>
                        <p>Подписи должника, агента и покупателя/инвестора, если применимо.</p>

                        <h2>15. Приложения к договору</h2>

                        <p>15.1. <strong>Сертификат</strong></p>
                        <p>Перечень обязательств и условия, подтверждающие владение долговыми обязательствами
                            TCPcredit.</p>

                        <p>15.2. <strong>Порядок внесения изменений в приложения</strong></p>
                        <p>Все изменения в приложения должны быть оформлены в письменной форме и подписаны
                            сторонами.</p>

                        <h2>Заключение</h2>

                        <p>Настоящая оферта вступает в силу с момента ее публикации и действует до момента отзыва.
                            Подписание договора означает полное согласие сторон с его условиями.</p>
                    </section>
                </div>
            </div>
            <div className={'main-container relative mt-auto'}>
                <Footer/>
            </div>
        </div>
    );
};

export default Offer;
