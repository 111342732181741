import React from 'react';
import ArrowRightLong from "../icons/ArrowRightLong";
import HeaderMenu from "./HeaderMenu";

const Header = () => {
    return (
        <div className={'header relative'}>
            <HeaderMenu/>

            <div className={'flex flex-column gap-24'}>
                <div className={'flex flex-column gap-24'}>
                    <h1 className={'header-title'}>
                        Инвестируйте <br/>
                        в долговые обязательства с доходностью до <span>60%</span> годовых
                    </h1>
                    <h2 className={'header-subtitle'}>
                        <span>Получите стабильный</span> доход с минимальными рисками
                    </h2>
                </div>

                <div className={'flex just-space header-bottom'}>
                    <div className={'flex gap-16 tablet-column'}>
                        <a title={'Купить долговые обязательства TCPcredit'} href={"/repayment.html"} className={'gr-button'}>
                            Купить сейчас
                        </a>
                        <a title={'Продать долговые обязательства TCPcredit'} href={'/repayment.html'} className={'gr-button is-bordered'}>
                            Погасить сейчас
                        </a>
                    </div>

                    <a title={'Доход от TCPcredit'} href={'/calculator.html'} className={'header-calc'}>
                        Калькулятор доходности: <br/><br/>
                        Интерактивный элемент <br/> для расчета дохода <ArrowRightLong/>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Header;
