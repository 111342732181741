import React from 'react';

const Footer = () => {
    return (
        <div className={'footer'}>
            <div className={'footer-top'}>
                <div className={'flex align-center just-space'}>
                    <ul className={'footer-menu flex align-center gap-24'}>
                        <li>
                            <a title={'Инвестиции в долговые обязательства TCPcredit'} href="/">Главная</a>
                        </li>
                        <li>
                            <a title={'О TCPcredit'} href="/#block-price">О продукте</a>
                        </li>
                        <li>
                            <a title={'О компании TradeChangePlace OÜ'} href="/#block-history">О компании</a>
                        </li>
                        <li>
                            <a title={'Блог об инвестициях в криптовалюту'} href="/#block-blog">Блог</a>
                        </li>
                        <li>
                            <a title={'Контакты TradeChangePlace OÜ'} href="/#block-contacts">Контакты</a>
                        </li>
                    </ul>
                    <ul className={'footer-menu flex align-center gap-24'}>
                    <li>
                            <a href="https://vk.com/" target={'_blank'}>VK</a>
                        </li>
                        <li>
                            <a href="https://web.telegram.org/k/" target={'_blank'}>Telegram</a>
                        </li>
                        <li>
                            <a href="https://web.whatsapp.com/" target={'_blank'}>Whats up</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={'footer-bottom'}>
                <ul className={'footer-menu flex align-center gap-24'}>
                    <li>
                        <a href="/privacy">Политика конфиденциальности</a>
                    </li>
                    <li>
                        <a href="/offer">Договор оферты</a>
                    </li>
                </ul>
            </div>

        </div>
    );
};

export default Footer;
