import React, {useEffect} from 'react';
import Header from "../../components/Header/Header";
import LinkDown from "../../components/icons/LinkDown";
import HistoryBtn from "../../components/icons/HistoryBtn";
import Screw from "../../components/icons/Screw";
import Round from "../../components/icons/Round";
import Star from "../../components/icons/Star";
import ReviewsCarousel from "../../components/ReviewsCarousel/ReviewsCarousel";
import CarouselArrow from "../../components/icons/CarouselArrow";
import Blog from "../../components/Blog/Blog";
import ArrowRightLong from "../../components/icons/ArrowRightLong";
import Footer from "../../components/Footer/Footer";
import {Link} from "react-router-dom";
import Advantages from "../../components/Advantages/Advantages";
import BenefitList from "../../components/BenefitList/BenefitList";
import FeedbackForm from "../../components/FeedbackForm/FeedbackForm";

const HomePage = () => {
    useEffect(() => {
        if (window.location.hash) {
            setTimeout(() => {
                const el = document.getElementById(window.location.hash?.replace("#", ''));
                if (!el) return;

                window.scroll({
                    top: el.offsetTop,
                    left: 0,
                    behavior: 'smooth'
                });
            })
        }
    }, [])

    return (
        <div className={'home-page'}>
            <div className={'main-container relative'}>
                <img className={'hd-bg-one'} src="./img/bg.png" alt="header-bg"/>
                <Header/>
                <img className={'hd-bg-two'} src="./img/bg.png" alt="header-bg"/>
            </div>
            <div className={'main-container'}>
                <Advantages/>
            </div>
            <div className={'main-container'} id={'block-history'}>
                <div className={'history flex align-center just-center relative'}>
                    <LinkDown/>
                    <div className={'history-content flex gap-12 just-center'}>
                        <div className={'history-column'}>
                            <div className={'history-btn relative'}>
                                <i>История</i>
                                <HistoryBtn/>
                            </div>
                            <div className={'history-girl'}>
                                <img src="./img/reznicova.jpg" alt="Резникова"/>
                            </div>
                        </div>
                        <div className={'history-column flex gap-12 flex-column'}>
                            <a title={''} href={'/history/ip-reznikova'} className={'history-item history-item-1 flex flex-column gap-12'}>
                                <div className="history-item-title history-item-title-1">
                                    ИП Резникова
                                </div>
                                <div className={'history-item-content history-item-content-1'}>
                                    Это динамично развивающийся бизнес, основанный Екатериной Резниковой, успешным
                                    предпринимателем и экспертом в области экономики. Благодаря уникальному подходу к
                                    работе и вниманию к деталям, ИП Резникова заслужило доверие и признание клиентов по
                                    всей стране.
                                </div>
                            </a>
                            <a title={''} href={'/history/yekaterina-vadimovna-reznikova'} className={'history-item history-item-2 relative'}>
                                <Screw/>
                                <div className={'history-item-content history-item-content-2 relative'}>
                                    Екатерина Вадимовна Резникова – основатель и руководитель ИП Резникова, родилась в
                                    1992 году. Окончив Саратовский государственный университет (СГУ) по специальности
                                    "Экономика и процессы управления", она начала свою карьеру в компании "Новокех", где
                                    приобрела ценный опыт и навыки. Вдохновленная идеей создания собственного бизнеса,
                                    основала ИП Резникова, чтобы реализовать свой потенциал и предложить клиентам лучший
                                    сервис и инновационные решения.
                                </div>
                            </a>
                        </div>
                        <div className={'history-column flex gap-12 flex-column'}>
                            <Link to={'/history/nadezhnyy-partner'} className={'history-item history-item-3 relative'}>
                                <Round/>
                                <Round/>
                                <div className={'history-item-content history-item-content-3 relative'}>
                                    ИП Резникова – ваш надежный партнер в Вашем деле. Доверьтесь профессионалам и
                                    получите качественный результат!
                                </div>
                            </Link>
                            <div className={'history-item history-item-4 flex flex-column gap-12 relative'}>
                                <Star/>
                                <Star/>
                                <div className="history-item-title history-item-title-4 relative">
                                    Контакты
                                </div>
                                <div
                                    className={'history-item-content history-item-content-4 flex flex-column gap-12 relative'}>
                                    Мы всегда рады вашим обращениям и готовы помочь в решении любых вопросов
                                    <div className={'flex flex-column gap-12 history-contact-wrap'}>
                                        <div className={'history-contact'}>
                                            <span>Номер телефона:</span><br/>
                                            +7 (977) 283-69-67
                                        </div>
                                        <div className={'history-contact'}>
                                            <span>Email:</span><br/>
                                            magazin.area@gmail.com
                                        </div>
                                        <div className={'history-contact'}>
                                            <span>Адрес:</span><br/>
                                            123456, Россия, г. Москва,
                                            ул. Примерная, д. 1
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'main-container'} id={'block-price'}>
                <div className="price">
                    <div className={'flex gap-16'}>

                        <div className={'flex gap-16 tablet-column'}>
                            <div className={'price-column-left flex gap-16 flex-column tablet-w-full'}>
                                <div className={'price-title'}>
                                    <span className={'page-title'}>Цены и услуги</span>
                                </div>
                                <div className={'flex gap-16'}>
                                    <div className={'price-item empty transparent'}></div>
                                    <div className={'price-item empty'}></div>
                                </div>
                                <div className={'flex gap-16'}>
                                    <div className={'price-item empty'}></div>
                                    <div className={'price-item empty transparent'}></div>
                                </div>
                                <div className={'flex gap-16'}>
                                    <div className={'price-item empty'}></div>
                                    <div className={'price-item empty transparent'}></div>
                                </div>
                            </div>

                            <div className={'flex gap-16 price-column-right tablet-w-full'}>
                                <div className={'price-column flex gap-16 flex-column'}>
                                    <Link to={'/price/kratkosrochnyye-dolgovyye-obyazatelstva'} className={'price-item'}>
                                        <div className={'price-item-title'}>
                                            Краткосрочные долговые обязательства
                                        </div>
                                        <div className={'price-item-description flex flex-column gap-24'}>
                                            <div>
                                                <div>Срок</div>
                                                <div className={'price-item-description-title'}>3-6 месяцев</div>
                                            </div>
                                            <div>
                                                <div>Доходность:</div>
                                                <div className={'price-item-description-title'}>16% годовых</div>
                                            </div>
                                            <div>
                                                Отличный выбор для тех, кто хочет быстро увеличить свой капитал с
                                                минимальными
                                                рисками. Ежемесячные выплаты процентов. Погашение онлайн в любое время.
                                            </div>
                                        </div>
                                    </Link>
                                    <div className={'price-item empty'}></div>
                                    <div className={'price-item empty'}></div>
                                </div>
                                <div className={'price-column flex gap-16 flex-column'}>
                                    <Link to={'/price/srednesrochnyye-dolgovyye-obyazatelstva'} className={'price-item'}>
                                        <div className={'price-item-title'}>
                                            Среднесрочные долговые обязательства
                                        </div>
                                        <div className={'price-item-description flex flex-column gap-24'}>
                                            <div>
                                                <div>Срок</div>
                                                <div className={'price-item-description-title'}>6-12 месяцев</div>
                                            </div>
                                            <div>
                                                <div>Доходность:</div>
                                                <div className={'price-item-description-title'}>18% годовых</div>
                                            </div>
                                            <div>
                                                Идеальный баланс между сроком и доходностью. Ежемесячные выплаты
                                                процентов.
                                                Погашение онлайн в любое время.
                                            </div>
                                        </div>
                                    </Link>
                                    <div className={'price-item empty transparent'}></div>
                                    <div className={'price-item empty'}></div>
                                </div>
                                <div className={'price-column flex gap-16 flex-column'}>
                                    <Link to={'/price/srednesrochnyye-dolgovyye-obyazatelstva-2'} className={'price-item'}>
                                        <div className={'price-item-title'}>
                                            Среднесрочные долговые обязательства
                                        </div>
                                        <div className={'price-item-description flex flex-column gap-24'}>
                                            <div>
                                                <div>Срок</div>
                                                <div className={'price-item-description-title'}>1-2 года</div>
                                            </div>
                                            <div>
                                                <div>Доходность:</div>
                                                <div className={'price-item-description-title'}>20% годовых</div>
                                            </div>
                                            <div>
                                                Надежный вариант для стабильного дохода. Ежемесячные выплаты процентов.
                                                Погашение онлайн в любое время.
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to={'/price/dolgosrochnyye-dolgovyye-obyazatelstva'} className={'price-item excludes'}>
                                        <div className={'price-item-title'}>
                                            Долгосрочные долговые обязательства
                                        </div>
                                        <div className={'price-item-description flex flex-column gap-24'}>
                                            <div>
                                                <div>Срок</div>
                                                <div className={'price-item-description-title'}>3 года</div>
                                            </div>
                                            <div>
                                                <div>Доходность:</div>
                                                <div className={'price-item-description-title'}>25% годовых</div>
                                            </div>
                                            <div>
                                                Максимальная доходность для тех, кто готов инвестировать на долгий срок.
                                                Ежемесячные выплаты процентов. Погашение онлайн в любое время.
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className={'price-column flex gap-16 flex-column'}>
                                    <Link to={'/price/dolgosrochnyye-dolgovyye-obyazatelstva-2'} className={'price-item'}>
                                        <div className={'price-item-title'}>
                                            Долгосрочные долговые обязательства
                                        </div>
                                        <div className={'price-item-description flex flex-column gap-24'}>
                                            <div>
                                                <div>Срок</div>
                                                <div className={'price-item-description-title'}>2-3 года</div>
                                            </div>
                                            <div>
                                                <div>Доходность:</div>
                                                <div className={'price-item-description-title'}>20% годовых</div>
                                            </div>
                                            <div>
                                                Надежный вариант для стабильного дохода. Ежемесячные выплаты процентов.
                                                Погашение онлайн в любое время.
                                            </div>
                                        </div>
                                    </Link>
                                    <div className={'price-item empty'}></div>
                                    <div className={'price-item empty transparent'}></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className={'main-container relative'}>
                <img className={'hd-bg-four'} src="./img/bg.png" alt="header-bg"/>
                <div className={'benefit flex align-start gap-24 mobile-column'}>
                    <div className="benefit-left mobile-w-full page-title">
                        Почему выгодны <br/>
                        долговые обязательства?
                    </div>
                    <div className="benefit-right mobile-w-full">
                        <BenefitList/>
                    </div>
                </div>
            </div>
            <div className={'main-container'}>
                <div className={'success flex align-start gap-24 mobile-column'}>
                    <div className="success-left mobile-w-full page-title">
                        История <br/>
                        успеха
                    </div>
                    <div className="success-right mobile-w-full">
                        <ReviewsCarousel/>
                    </div>
                </div>
            </div>
            <div className={'main-container'} id="block-blog">
                <div className={'white-section'}>
                    <div className={'blog'}>
                        <div className={'blog-title flex align-center just-space page-title'}>
                            Блог
                            <Link to={'/blog'} className={'blog-arrow'}>
                                <CarouselArrow/>
                            </Link>
                        </div>
                        <Blog/>
                    </div>

                    <div className={'feedback'} id={'block-contacts'}>
                        <FeedbackForm/>
                    </div>
                </div>
            </div>
            <div className={'main-container relative'}>
                <img className={'hd-bg-three'} src="./img/bg.png" alt="header-bg"/>
                <div className={'offer'}>
                    <div className={'offer-title page-title'}>
                        Договор оферты
                    </div>
                    <div className={'offer-content flex align-start gap-24 mobile-column'}>
                        <div className={'offer-left'}>
                            Что такое договор оферты?
                        </div>
                        <div className={'offer-right flex gap-24 flex-column'}>
                            <div className="offer-right-resume">
                                Оферта — это предложение заключить договор на заранее определённых условиях. Условия
                                конкретно прописаны в нашем договоре, вы можете ознакомиться или скачать его ниже по
                                ссылкам
                            </div>
                            <div className={'flex gap-24 offer-right-link'}>
                                <a href="/Публичная оферта на продажу долговых обязательств TCPcredit.pdf?download">
                                    Скачать договор <ArrowRightLong/>
                                </a>
                                {/*<a href="#">*/}
                                {/*    Порядок оплаты <ArrowRightLong/>*/}
                                {/*</a>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'main-container relative'}>
                <Footer/>
            </div>
        </div>
    );
};

export default HomePage;
