import React, {useRef} from 'react';
import logo from "../../assets/img/logo.png"

const HeaderMenu = () => {

    const toggleRef = useRef(null);
    const menuRef = useRef(null);

    const toggleHandler = () => {
        if (toggleRef.current && menuRef.current) {
            const menu = menuRef.current;
            const toggle = toggleRef.current;
            if (menu.classList.contains('fadeInLeftBig')) {
                menu.classList.remove('fadeInLeftBig');
                menu.classList.add('fadeOutLeftBig');
                toggle.classList.remove('active');
            } else {
                menu.style.display = 'flex';
                menu.classList.remove('fadeOutLeftBig');
                menu.classList.add('fadeInLeftBig');
                toggle.classList.add('active');
            }
        }
    }

    const scrollIntoAnchor = (e) => {
        if (window.location.pathname === '/') {
            e.preventDefault();
        }

        const target = e.currentTarget;

        const href = target.getAttribute('href');
        if (!href) return;

        const el = document.getElementById(href.replace("/#", ''));
        if (!el) return;

        window.scroll({
            top: el.offsetTop,
            left: 0,
            behavior: 'smooth'
        });
    }

    return (
        <div className={'header-menu flex just-space align-center'}>
            <div ref={toggleRef} className="toggle" id="toggle-btn" onClick={toggleHandler}><span
                className="toggle-icon"></span></div>
            <a title={'Инвестиции в долговые обязательства TCPcredit'} href={'/'} className={'logo'}>
                <img src={logo} alt="Долговые обязательства TCPcredit" />
            </a>

            <div ref={menuRef} className={'menu flex gap-24 animated'}>
                <a title={'Инвестиции в долговые обязательства TCPcredit'} href="/">Главная</a>
                <a title={'О TCPcredit'} href="/#block-price" onClick={scrollIntoAnchor}>О продукте</a>
                <a title={'О компании TradeChangePlace OÜ'} href="/#block-history" onClick={scrollIntoAnchor}>О компании</a>
                <a title={'Блог об инвестициях в криптовалюту'} href="/#block-blog" onClick={scrollIntoAnchor}>Блог</a>
                <a title={'Контакты TradeChangePlace OÜ'} href="/#block-contacts" onClick={scrollIntoAnchor}>Контакты</a>
            </div>
        </div>
    );
};

export default HeaderMenu;
