import React from 'react';

const Quotes = () => {
    return (
        <svg className={'quotes-icon'} width="127" height="124" viewBox="0 0 127 124" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_29_189)">
                <path
                    d="M1.23155 123.381L1.3069 67.2589C1.36229 25.9978 17.0991 3.81493 48.5173 0.710074L53.8125 0.192685L53.7752 27.9914L50.0683 28.511C37.3597 29.8925 30.9906 41.598 30.961 63.6272L30.9498 72.0192L53.7161 72.0498L53.6471 123.451L1.23155 123.381ZM126.839 28.0895L123.132 28.6091C110.424 29.9906 104.055 41.6961 104.025 63.7253L104.014 72.1173L126.78 72.1479L126.711 123.549L74.2955 123.479L74.3709 67.357C74.4263 26.0959 90.1631 3.91302 121.581 0.808166L126.877 0.290778L126.839 28.0895Z"
                    fill="#D9E6D7"/>
            </g>
            <defs>
                <clipPath id="clip0_29_189">
                    <rect width="126.59" height="123.259" fill="white"
                          transform="translate(0.329987 0.12085) rotate(0.0769231)"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default Quotes;
