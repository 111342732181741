import React, {useRef, useState} from 'react';
import "./ReviewsCarousel.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from "react-responsive-carousel";
import Quotes from "../icons/Quotes";
import CarouselArrow from "../icons/CarouselArrow";

const slideItems = [
    {
        id: 1,
        text: `Меня зовут Валерий, и я хочу поделиться своей историей успеха, которая началась с покупки
                            долговых обязательств компании TradeChangePlace OÜ. Вложив свои средства в этот финансовый
                            инструмент, я смог не только сохранить свои сбережения, но и значительно приумножить их.`,
        img: './img/1.jpg',
        user: `Валерий <br/>Тихонов`
    },
    {
        id: 2,
        text: `Меня зовут Андрей, и я хочу поделиться своей историей успеха, которая началась с покупки
                            долговых обязательств компании TradeChangePlace OÜ. Вложив свои средства в этот финансовый
                            инструмент, я смог не только сохранить свои сбережения, но и значительно приумножить их.`,
        img: './img/2.jpg',
        user: `Андрей <br/>Проскурин`
    },
    {
        id: 3,
        text: `Меня зовут Светлана, и я хочу поделиться своей историей успеха, которая началась с покупки
                            долговых обязательств компании TradeChangePlace OÜ. Вложив свои средства в этот финансовый
                            инструмент, я смог не только сохранить свои сбережения, но и значительно приумножить их.`,
        img: './img/3.jpg',
        user: `Светлана <br/>Струкова`
    }
]

const ReviewsCarousel = () => {
    const carouselRef = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(0);

    const renderArrowPrev = () => {
        return (
            <div className={'car-arrows car-arrows-prev'} onClick={() => {
                if (currentSlide <= 0) return;
                if (carouselRef.current) {
                    const newIn = currentSlide - 1;
                    carouselRef.current.moveTo(newIn);
                    setCurrentSlide(newIn);
                }
            }}>
                <CarouselArrow/>
            </div>
        )
    }

    const renderArrowNext = () => {
        return (
            <div className={'car-arrows car-arrows-next'} onClick={() => {
                if (currentSlide >= slideItems.length - 1) return;
                if (carouselRef.current) {
                    const newIn = currentSlide + 1;
                    carouselRef.current.moveTo(newIn);
                    setCurrentSlide(newIn);
                }
            }}>
                <CarouselArrow/>
            </div>
        )
    }

    const updateCurrentSlide = (index) => {
        setCurrentSlide(index);
    };

    return (
        <Carousel
            ref={carouselRef}
            showArrows
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            swipeable
            renderArrowPrev={renderArrowPrev}
            renderArrowNext={renderArrowNext}
            onChange={updateCurrentSlide}
            infiniteLoop
        >

            {
                slideItems.map(s => {
                    return (
                        <div className={'rew-car-item'} key={s.id}>
                            <div className="rew-car-item-1"></div>
                            <div className="rew-car-item-2"></div>
                            <div className="rew-car-item-3"></div>
                            <div className="rew-car-item-4"></div>
                            <div className="rew-car-item-main relative">
                                <Quotes/>
                                <div className={'relative flex flex-column just-space align-start'}>
                                    <div className={'btn-more'}>
                                        Подробнее
                                    </div>
                                    <div className={'text'}>
                                        {s.text}
                                    </div>
                                    <div className={'user flex align-center gap-16'}>
                                        <div className="user-icon">
                                            <img src={s.img} alt={'user'}/>
                                        </div>
                                        <div className="user-name" dangerouslySetInnerHTML={{__html: s.user}}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }

        </Carousel>
    );
};

export default ReviewsCarousel;
