const AdvTwo = () => {
    return (
        <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M24.8884 6.87698C24.8884 3.49421 27.6306 0.751953 31.0134 0.751953H38.8938C42.2765 0.751953 45.0188 3.49422 45.0188 6.87698V63.023C45.0188 66.4057 42.2765 69.148 38.8938 69.148H31.0134C27.6306 69.148 24.8884 66.4057 24.8884 63.023V6.87698Z"
                fill="#97D28B" fillOpacity="0.1"/>
            <path opacity="0.5" fillRule="evenodd" clipRule="evenodd"
                  d="M14.1808 34.9744H6.30041C4.04524 34.9744 2.21707 36.8026 2.21707 39.0578V63.0222C2.21707 65.2774 4.04524 67.1054 6.30041 67.1054H14.1808C16.436 67.1054 18.2642 65.2774 18.2642 63.0222V39.0578C18.2642 36.8026 16.436 34.9744 14.1808 34.9744ZM6.30041 32.9327C2.91766 32.9327 0.175385 35.6749 0.175385 39.0578V63.0222C0.175385 66.4049 2.91764 69.1472 6.30041 69.1472H14.1808C17.5635 69.1472 20.3058 66.4049 20.3058 63.0222V39.0578C20.3058 35.6749 17.5635 32.9327 14.1808 32.9327H6.30041Z"
                  fill="#16ECFB"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M38.8938 2.79362H31.0134C28.7582 2.79362 26.93 4.62181 26.93 6.87698V63.023C26.93 65.2782 28.7582 67.1063 31.0134 67.1063H38.8938C41.1489 67.1063 42.9771 65.2782 42.9771 63.023V6.87698C42.9771 4.6218 41.1489 2.79362 38.8938 2.79362ZM31.0134 0.751953C27.6306 0.751953 24.8884 3.49421 24.8884 6.87698V63.023C24.8884 66.4057 27.6306 69.148 31.0134 69.148H38.8938C42.2765 69.148 45.0188 66.4057 45.0188 63.023V6.87698C45.0188 3.49422 42.2765 0.751953 38.8938 0.751953H31.0134Z"
                  fill="#16ECFB"/>
            <path opacity="0.5" fillRule="evenodd" clipRule="evenodd"
                  d="M63.6152 15.9159H55.7348C53.4797 15.9159 51.6515 17.7441 51.6515 19.9993V63.023C51.6515 65.278 53.4796 67.1062 55.7348 67.1062H63.6152C65.8704 67.1062 67.6986 65.278 67.6986 63.023V19.9993C67.6986 17.7441 65.8704 15.9159 63.6152 15.9159ZM55.7348 13.8743C52.3521 13.8743 49.6098 16.6166 49.6098 19.9993V63.023C49.6098 66.4057 52.3521 69.148 55.7348 69.148H63.6152C66.9979 69.148 69.7402 66.4057 69.7402 63.023V19.9993C69.7402 16.6166 66.9979 13.8743 63.6152 13.8743H55.7348Z"
                  fill="#16ECFB"/>
        </svg>

    )
}

export default AdvTwo;
