import React, {useEffect} from 'react';
import HeaderMenu from "../../components/Header/HeaderMenu";
import Footer from "../../components/Footer/Footer";
import {useParams} from "react-router-dom";
import {BLOG_DATA} from "../../utils/blog";

const SingleBlogItem = () => {
    const params = useParams();

    useEffect(() => {
        const hash = params.hash;

        if( BLOG_DATA[hash] )
            document.title = 'Блог криптовалюта: ' + BLOG_DATA[hash].title

        window.scrollTo(0, 0);
    }, [params]);

    const renderContent = () => {
        const hash = params.hash;
        if (hash) {
            return BLOG_DATA[hash]
        }
    }

    return (
        <div className={'flex flex-column h-full'}>
            <div className={'main-container'}>
                <div className={'header relative'}>
                    <HeaderMenu/>
                </div>
            </div>
            <div className={'main-container'}>
                <div className={'white-section'}>
                    <div className={'blog'}>
                        <h1 className={'blog-title flex align-center just-space page-title'}>
                            {renderContent().title}
                        </h1>
                        <div className={'text-black'}>
                            <div dangerouslySetInnerHTML={{__html: renderContent().content}}>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'main-container relative mt-auto'}>
                <Footer/>
            </div>
        </div>
    );
};

export default SingleBlogItem;
