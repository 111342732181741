import React, {useRef, useState} from 'react';
import CarouselArrow from "../icons/CarouselArrow";

const FeedbackForm = () => {
    const form = useRef(null);
    const [isSuccess, setIsSuccess] = useState(false);
    const [email, setEmail] = useState('');
    const [text, setText] = useState({
        text: '',
        isError: false
    });
    const onSubmit = async () => {
        if (!form.current) return;
        if (!email) {
            setIsSuccess(true);
            setText({
                text: 'Введите почту',
                isError: true
            });
            return;
        }

        const data = {'email': email};

        try {
            const response = await fetch('/feedback.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                const result = await response.json();
                if (result.status === "success") {
                    setIsSuccess(true);
                    setText({
                        text: 'Ваша заявка успешно отправлена',
                        isError: false
                    });
                    form.current.reset();
                    return;
                }
                setText({
                    text: 'Ошибка при отправке заявки',
                    isError: true
                });
            } else {
                console.error('Ошибка:', response.statusText);
                setText({
                    text: 'Ошибка при отправке заявки',
                    isError: true
                });
            }
        } catch (error) {
            console.error('Ошибка:', error);
            setText({
                text: 'Ошибка при отправке заявки',
                isError: true
            });
        }
    }

    const onInput = (e) => {
        setEmail(e.target.value)
    }

    return (
        <form action={'#'} className={'feedback-content flex gap-24 align-start flex-column'} ref={form}>
            <div className={'flex w-full gap-24 mobile-column'}>
                <div className={'w-full feedback-title page-title'}>
                    Форма обратной связи
                </div>
                <div className={'w-full feedback-description'}>
                    <span>
                        Свяжитесь с нами чтобы заказать консультацию
                    </span>
                </div>
            </div>
            <div className={'flex w-full gap-24 mobile-column'}>
                <div className={'w-full'}>
                    <div className={'form-input'}>
                        <input type="email" placeholder={'Email'} required onInput={onInput}/>
                    </div>
                </div>
                <div className={'w-full'}>
                    <div className={'form-send flex just-space align-center'} onClick={onSubmit}>
                        Отправить
                        <CarouselArrow/>
                    </div>
                </div>
            </div>
            {isSuccess && <div className={text.isError ? 'is-error' : 'is-success'}>{text.text}</div>}
        </form>
    );
};

export default FeedbackForm;
