import React, {useEffect} from 'react';
import HeaderMenu from "../../components/Header/HeaderMenu";
import {useParams} from "react-router-dom";
import {ADVANTAGES_DATA} from "../../utils/advantages";

const SingleAdvantage = () => {

    const params = useParams();

    useEffect(() => {
        const hash = params.hash;

        document.title = 'Инвестиции в TCPcredit ' + ADVANTAGES_DATA[hash].title
        window.scrollTo(0, 0);
    }, [params]);

    const renderContent = () => {
        const hash = params.hash;
        if (hash) {
            return ADVANTAGES_DATA[hash]
        }
    }

    return (
        <div className={'flex flex-column gap-24'}>
            <div className={'main-container'}>
                <div className={'header relative'}>
                    <HeaderMenu/>
                </div>
            </div>
            <div className={'main-container'}>
                <div className={'flex flex-column gap-24'}>
                    <h1 className={'adv-title page-title'}>
                        {renderContent().title}
                    </h1>
                    <div dangerouslySetInnerHTML={{__html: renderContent().content}}>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default SingleAdvantage;
