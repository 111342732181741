import React from 'react';

const LinkDown = () => {
    return (
        <svg className={'link-down-icon'} width="68" height="142" viewBox="0 0 68 142" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_0_213)">
                <g clipPath="url(#clip1_0_213)">
                    <g clipPath="url(#clip2_0_213)">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M36.1548 29.2954C51.9944 29.2856 64.8321 16.4419 64.8321 0.599945H66.6396L66.6356 141.87H64.8321C64.8321 126.022 51.9846 113.174 36.1366 113.174H19.9494C10.1968 113.174 2.29065 105.269 2.29065 95.5157V46.9542C2.29065 37.2015 10.1968 29.2954 19.9494 29.2954H36.1548Z"
                              fill="#262D29"/>
                    </g>
                </g>
            </g>
            <g clipPath="url(#clip3_0_213)">
                <g clipPath="url(#clip4_0_213)">
                    <g clipPath="url(#clip5_0_213)">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M33.9076 78.7997L42.7877 69.9196L44.1988 71.3307L33.6153 81.9142C33.2256 82.304 32.5938 82.304 32.2042 81.9142L21.6206 71.3307L23.0317 69.9196L31.9119 78.7997V60.3192L33.9076 60.3192V78.7997Z"
                              fill="#97D28B"/>
                    </g>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_0_213">
                    <rect width="64.75" height="141.26" fill="white" transform="translate(2.29004 0.599945)"/>
                </clipPath>
                <clipPath id="clip1_0_213">
                    <rect width="64.75" height="141.26" fill="white" transform="translate(2.29004 0.599945)"/>
                </clipPath>
                <clipPath id="clip2_0_213">
                    <rect width="64.7487" height="141.27" fill="white" transform="translate(2.29065 0.599945)"/>
                </clipPath>
                <clipPath id="clip3_0_213">
                    <rect width="23.95" height="22.95" fill="white" transform="translate(20.6801 59.7399)"/>
                </clipPath>
                <clipPath id="clip4_0_213">
                    <rect width="23.95" height="22.95" fill="white" transform="translate(20.6801 59.7399)"/>
                </clipPath>
                <clipPath id="clip5_0_213">
                    <rect width="23.9478" height="22.95" fill="white" transform="translate(20.6812 59.7399)"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default LinkDown;
