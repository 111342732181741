import React from 'react';

const Star = () => {
    return (
        <svg className={'star-icon'} width="94" height="92" viewBox="0 0 94 92" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M47.2573 0.908661L50.3679 9.08391C56.3407 24.7794 68.2942 36.8686 83.1158 42.202L93.5994 45.9742L83.1158 49.7464C68.2942 55.0799 56.3407 67.1691 50.3679 82.8646L47.2573 91.0398L44.1466 82.8646C38.1738 67.1691 26.2197 55.0799 11.3982 49.7464L0.9151 45.9742L11.3982 42.202C26.2197 36.8686 38.1738 24.7794 44.1466 9.08391L47.2573 0.908661Z"
                fill="#97D28B"/>
        </svg>
    );
};

export default Star;
