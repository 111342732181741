import React from 'react';

const HistoryBtn = () => {
    return (
        <svg className={'history-btn-icon'} viewBox="0 0 522 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M27.4396 0.659912H495.16V75.5999C495.16 88.8547 484.414 99.5999 471.16 99.5999H51.4397C38.1848 99.5999 27.4396 88.8548 27.4396 75.5999V0.659912Z"
                fill="#262D29"/>
            <g clipPath="url(#clip0_0_144)">
                <g clipPath="url(#clip1_0_144)">
                    <g clipPath="url(#clip2_0_144)">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M521.629 0.659912C506.734 0.659913 494.659 12.7349 494.659 27.6299L494.659 0.659914L521.629 0.659912Z"
                              fill="#262D29"/>
                    </g>
                </g>
            </g>
            <g clipPath="url(#clip3_0_144)">
                <g clipPath="url(#clip4_0_144)">
                    <g clipPath="url(#clip5_0_144)">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M521.629 0.659912C506.734 0.659913 494.659 12.7349 494.659 27.6299L494.659 0.659914L521.629 0.659912Z"
                              fill="#262D29"/>
                    </g>
                </g>
            </g>
            <g clipPath="url(#clip6_0_144)">
                <g clipPath="url(#clip7_0_144)">
                    <g clipPath="url(#clip8_0_144)">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M0.96991 0.659914C15.865 0.659913 27.9399 12.7349 27.9399 27.6299V0.659912L0.96991 0.659914Z"
                              fill="#262D29"/>
                    </g>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_0_144">
                    <rect width="26.97" height="26.97" fill="white" transform="translate(494.659 0.659912)"/>
                </clipPath>
                <clipPath id="clip1_0_144">
                    <rect width="26.97" height="26.97" fill="white" transform="translate(494.659 0.659912)"/>
                </clipPath>
                <clipPath id="clip2_0_144">
                    <rect width="26.97" height="26.97" fill="white" transform="translate(494.659 0.659912)"/>
                </clipPath>
                <clipPath id="clip3_0_144">
                    <rect width="26.97" height="26.97" fill="white" transform="translate(494.659 0.659912)"/>
                </clipPath>
                <clipPath id="clip4_0_144">
                    <rect width="26.97" height="26.97" fill="white" transform="translate(494.659 0.659912)"/>
                </clipPath>
                <clipPath id="clip5_0_144">
                    <rect width="26.97" height="26.97" fill="white" transform="translate(494.659 0.659912)"/>
                </clipPath>
                <clipPath id="clip6_0_144">
                    <rect width="26.97" height="26.97" fill="white" transform="translate(0.96991 0.659912)"/>
                </clipPath>
                <clipPath id="clip7_0_144">
                    <rect width="26.97" height="26.97" fill="white" transform="translate(0.96991 0.659912)"/>
                </clipPath>
                <clipPath id="clip8_0_144">
                    <rect width="26.97" height="26.97" fill="white" transform="translate(0.96991 0.659912)"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default HistoryBtn;
