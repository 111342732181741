import './App.scss';
import React from "react";
import HomePage from "./pages/HomePage/HomePage";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import BlogPage from "./pages/BlogPage/BlogPage";
import SingleBlogItem from "./pages/BlogPage/SingleBlogItem";
import AdvantagesPages from "./pages/AdvantagesPages/AdvantagesPages";
import SingleAdvantage from "./pages/AdvantagesPages/SingleAdvantage";
import HistoryPage from "./pages/HistoryPage/HistoryPage";
import SingleHistory from "./pages/HistoryPage/SingleHistory";
import SinglePrice from "./pages/PricePage/SinglePrice";
import Privacy from "./pages/Privacy/Privacy";
import Offer from "./pages/Offer/Offer";

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/blog" element={<BlogPage/>}/>
                    <Route path="/blog/:hash" element={<SingleBlogItem/>}/>
                    <Route path="/advantages" element={<AdvantagesPages/>}/>
                    <Route path="/advantages/:hash" element={<SingleAdvantage/>}/>
                    <Route path="/history" element={<HistoryPage/>}/>
                    <Route path="/history/:hash" element={<SingleHistory/>}/>
                    <Route path="/price/:hash" element={<SinglePrice/>}/>
                    <Route path="/privacy" element={<Privacy/>}/>
                    <Route path="/offer" element={<Offer/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
