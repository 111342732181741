import React from 'react';

const CarouselArrow = () => {
    return (
        <svg className={'carousel-arrow-icon'} width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M6.9315 14.3213L0.219974 7.60977L6.9315 0.898223L7.97373 1.94046L3.04134 6.87282H15.2644V8.34673H3.04134L7.97373 13.2791L6.9315 14.3213Z"
                  fill="#DCEFD8"/>
        </svg>
    );
};

export default CarouselArrow;
