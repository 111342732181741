const ArrowRightLong = () => {
    return (
        <svg className={'arrow-right-long'} width="45" height="8" viewBox="0 0 45 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M44.2087 4.35355C44.404 4.15829 44.404 3.8417 44.2087 3.64644L41.0267 0.464462C40.8315 0.2692 40.5149 0.2692 40.3196 0.464462C40.1244 0.659724 40.1244 0.976307 40.3196 1.17157L43.148 4L40.3196 6.82842C40.1244 7.02369 40.1244 7.34027 40.3196 7.53553C40.5149 7.73079 40.8315 7.73079 41.0267 7.53553L44.2087 4.35355ZM3.99369e-08 4.5L43.8552 4.5L43.8552 3.5L-3.99369e-08 3.5L3.99369e-08 4.5Z"
                fill="white"/>
        </svg>
    )
}

export default ArrowRightLong;
