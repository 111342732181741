import React from 'react';

const Round = () => {
    return (
        <svg width="91" height="91" viewBox="0 0 91 91" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.229919" y="0.489868" width="89.94" height="89.94" rx="44.97" fill="#ACDBA3"/>
        </svg>
    );
};

export default Round;
