import React from 'react';
import HeaderMenu from "../../components/Header/HeaderMenu";
import Footer from "../../components/Footer/Footer";

const Privacy = () => {
    return (
        <div className={'flex flex-column gap-24'}>
            <div className={'main-container'}>
                <div className={'header relative'}>
                    <HeaderMenu/>
                </div>
            </div>
            <div className={'main-container'}>
                <div className={'flex flex-column gap-24'}>
                    <h1 className={'adv-title page-title'}>
                        Политика конфиденциальности
                    </h1>
                    <section>
                        <p>ИП Резникова Е.В. (далее "Компания") уважает ваше право на конфиденциальность и обязуется
                            защищать ваши персональные данные. Данная политика конфиденциальности объясняет, как мы
                            собираем, используем и защищаем вашу информацию.</p>
                        <h3>Сбор информации</h3>
                        <p>Мы собираем информацию, которую вы предоставляете нам напрямую, например, при заполнении
                            формы на нашем сайте. Это может включать ваше имя, адрес электронной почты, номер телефона и
                            другие контактные данные.</p>
                        <h3>Использование информации</h3>
                        <p>Собранная информация используется для обработки ваших запросов, улучшения качества наших
                            услуг и коммуникации с вами.</p>
                        <h3>Защита информации</h3>
                        <p>Мы принимаем все необходимые меры для защиты вашей информации от несанкционированного
                            доступа, изменения или уничтожения.</p>
                        <h3>Изменения в политике конфиденциальности</h3>
                        <p>Мы можем время от времени обновлять данную политику конфиденциальности. Мы уведомим вас о
                            любых изменениях, разместив новую политику на нашем сайте.</p>
                        <h3>Контакты</h3>
                        <p>Если у вас есть вопросы по поводу данной политики конфиденциальности, вы можете связаться с
                            нами по телефону: +7 977 283-69-67 или по электронной почте: <a
                                href="mailto:magazin.area@gmail.com">magazin.area@gmail.com</a></p>
                    </section>
                </div>
            </div>
            <div className={'main-container relative mt-auto'}>
                <Footer/>
            </div>
        </div>
    );
};

export default Privacy;
