import React from 'react';
import Blog from "../../components/Blog/Blog";
import HeaderMenu from "../../components/Header/HeaderMenu";
import Footer from "../../components/Footer/Footer";

const BlogPage = () => {
    return (
        <div className={'flex flex-column h-full'}>
            <div className={'main-container'}>
                <div className={'header relative'}>
                    <HeaderMenu/>
                </div>
            </div>
            <div className={'main-container'}>
                <div className={'white-section'}>
                    <div className={'blog'}>
                        <div className={'blog-title flex align-center just-space page-title'}>
                            Блог
                        </div>
                        <Blog/>
                    </div>
                </div>
            </div>
            <div className={'main-container relative mt-auto'}>
                <Footer/>
            </div>
        </div>
    );
};

export default BlogPage;
