import React from 'react';
import AdvArrow from "../icons/AdvArrow";
import AdvOne from "../icons/AdvOne";
import AdvTwo from "../icons/AdvTwo";
import AdvThree from "../icons/AdvThree";

const Advantages = () => {
    return (
        <div className={'advantages'}>
            <div className={'flex flex-column gap-24'}>
                <div className={'adv-title page-title'}>
                    Преимущества
                </div>
                <div className={'adv-content flex align-center gap-24 mobile-column'}>
                    <a title={'Надежный эмитент TradeChangePlace OÜ'} href={'/advantages/nadezhnyy-emitent'}
                          className={'adv-item flex flex-column just-space relative mobile-w-full'}>
                        <div className={'adv-arrow'}><AdvArrow/></div>
                        <div className={'adv-icon'}>
                            <AdvOne/>
                        </div>
                        <div className={'adv-item-title'}>
                            Надежный<br/>эмитент
                        </div>
                    </a>
                    <a title={'Высокая доходность от криптовалюты TCPcredit'} href={'/advantages/vysokaya-dokhodnost'}
                          className={'adv-item flex flex-column just-space relative mobile-w-full'}>
                        <div className={'adv-arrow'}><AdvArrow/></div>
                        <div className={'adv-icon'}>
                            <AdvTwo/>
                        </div>
                        <div className={'adv-item-title'}>
                            Высокая<br/>доходность
                        </div>
                    </a>
                    <a title={'Высокая ликвидность TCPcredit'} href={'/advantages/vysokaya-likvidnost'}
                          className={'adv-item flex flex-column just-space relative mobile-w-full'}>
                        <div className={'adv-arrow'}><AdvArrow/></div>
                        <div className={'adv-icon'}>
                            <AdvThree/>
                        </div>
                        <div className={'adv-item-title'}>
                            Ликвидность
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Advantages;
